import React, { Component } from 'react';
// import * as firebase from 'firebase/app';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import './Login.scss';
import $ from 'jquery';
import Bi from '../intro/Bi';
import { EmailAuthProvider, FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, getAuth, getRedirectResult, signInWithRedirect } from 'firebase/auth';
class Login extends Component {
    intFnc
    constructor(props) {
        super(props);
        this.state = {
            loaded: "off",
            email_ing: false,
        }
        this.history = props.history
    }
    componentWillUnmount() {
        console.log("email_ing unmount")
        clearInterval(this.intFnc);

    }

    componentDidMount() {
        let THIS = this;
        // const googleAuthProvider = new GoogleAuthProvider()
        // const facebookAuthProvider = new FacebookAuthProvider();
        // const emailAuthProvider = new EmailAuthProvider();
        // let target = "popup"
        // if (this.is_Android()) {
        //     target = "redirect"
        // } else {

        // }
        let target = "popup"
        if (!this.is_Android() && navigator.userAgent.indexOf("moshview") != -1) {
            target = "redirect"
        }
        if(this.is_Android()){
            target = "redirect"

        }
        // if(this.is_Android()){
        //     target = "redirect"
        // }
        console.log('signInFlow target', target)
        var uiConfig = {
            callbacks: {
                signInSuccessWithAuthResult: function (authResult, redirectUrl) {
                    // User successfully signed in.
                    // Return type determines whether we continue the redirect automatically
                    // or whether we leave that to developer to handle.
                    console.log("!!!signInSuccessWithAuthResult...auth signInSuccessWithAuthResult", authResult, redirectUrl)

                    return true;
                },
                uiShown: function () {
                    // The widget is rendered.
                    // Hide the loader.
                    console.log("...auth uiShown")
                    //   document.getElementById('loader').style.display = 'none';
                },
                customParameters: {
                    // Forces account selection even when one account
                    // is available.
                    prompt: 'select_account'
                  }
            },
            signInSuccessUrl: '/todo',
            signInOptions: [
                // Leave the lines as is for the providers you want to offer your users.
                GoogleAuthProvider.PROVIDER_ID,
                FacebookAuthProvider.PROVIDER_ID,
                {
                    provider: EmailAuthProvider.PROVIDER_ID,
                    signInMethod: EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                },
            ],
            // callbacks: { 'signInSuccess': function(currentUser, credential, redirectUrl) {  return false; } },
            // credentialHelper: firebaseui.auth.CredentialHelper.NONE,
            // signInFlow:"redirect",
            signInFlow: target,
            // tosUrl and privacyPolicyUrl accept either url string or a callback
            // function.
            // Terms of service url/callback.
            tosUrl: function () {
                THIS.history.replace("/setting/terms");
                // window.location.assign('<your-privacy-policy-url>');
            },
            // Privacy policy url/callback.
            privacyPolicyUrl: function () {
                THIS.history.replace("/setting/privacy");
                // window.location.assign('<your-privacy-policy-url>');
            }
        };
        const auth = getAuth()//firebase.auth()//getAuth();
        var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
        // var ui = new firebaseui.auth.AuthUI(auth);
        // Disable auto-sign in.
        ui.disableAutoSignIn();
        // The start method will wait until the DOM is loaded.
        ui.start('#firebaseui-auth-container', uiConfig);

        let settingName = () => {
            document.querySelectorAll('[data-provider-id="google.com"] >span')[1].innerText = '구글로 시작하기'
            document.querySelectorAll('[data-provider-id="facebook.com"] >span')[1].innerText = '페이스북으로 시작하기'
            document.querySelectorAll('[data-provider-id="password"] >span')[1].innerText = '이메일로 시작하기'
            $('.firebaseui-card-footer').hide();
        }
        const intWrap = () => {
            let THIS = this;
            let email_ing = false
            this.intFnc = setInterval(() => {
                if (document.querySelectorAll('.firebaseui-idp-list')[0]) {
                    email_ing = false;
                } else {
                    email_ing = true;
                }
                this.setState({
                    email_ing: email_ing
                })
                if (document.querySelectorAll('[data-provider-id="google.com"] >span')[1]) {
                    settingName();
                    this.setState({
                        loaded: "on",
                    })
                }
            }, 100)
        }


        // let auth = firebase.auth();
        // let auth = getAuth();
        console.log('auth', auth)
        // getRedirectResult(auth)
        getRedirectResult(auth).then(function (result) {
            console.log("auth in login result:", result)
            console.log(result);
            if (result == null) {
                intWrap();
                return;
            }
            // The firebase.User instance:
            var user = result.user;
            if (user == null) {
                intWrap();
            }
        }, function (error) {
            console.log("auth error")

            // The provider's account email, can be used in case of
            // auth/account-exists-with-different-credential to fetch the providers
            // linked to the email:
            var email = error.email;
            // The provider's credential:
            var credential = error.credential;
            // In case of auth/account-exists-with-different-credential error,
            // you can fetch the providers using this:
            if (error.code === 'auth/account-exists-with-different-credential') {
                // auth.fetchSignInMethodsForEmail(email).then(function(providers) {
                //   // The returned 'providers' is a list of the available providers
                //   // linked to the email address. Please refer to the guide for a more
                //   // complete explanation on how to recover from this error.
                // });
            }
        });

    }
    clickTerms() {
        this.history.push("/setting/terms");
    }
    clickPrivacy() {
        this.history.push("/setting/privacy");
    }
    iOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    is_Android() {
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
        return isAndroid
    }
    appleLogin() {
        const provider = new OAuthProvider('apple.com');
        // const provider = new firebase.auth.OAuthProvider('apple.com');
        const auth = getAuth();
        // firebase
        // .auth()
        // .signInWithPopup(provider)
        auth
            .signInWithRedirect(provider)
            .then(function (result) {
                // The signed-in user info.
                var user = result.user;
                // You can also get the Apple OAuth Access and ID Tokens.
                var accessToken = result.credential.accessToken;
                var idToken = result.credential.idToken;
                console.log("apple result")
                console.log(result);

                // ...
            })
            .catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;

                // ...
            });

    }
    googleLogin = () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        signInWithRedirect(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });

    }
    render() {
        // console.log("this.state.loaded:"+this.state.loaded)

        return (
            <div className={"login-wrap " + this.state.loaded}>
                <div className="bg"></div>
                <div className="bi-cont-full-wrap">
                    <div className="bi-cont">
                        <Bi />
                    </div>
                </div>

                <div className="login-contents">
                    <Bi />
                    {/* <div onClick={this.googleLogin}>google</div> */}
                    <div id="firebaseui-auth-container" className="firebaseui-auth-container"></div>
                    {
                        !this.is_Android() && !this.state.email_ing && <div id="apple-login" className="apple-login" onClick={() => this.appleLogin()}></div>
                    }
                    <div className="agree-text">
                        <span>
                            시작하면<br /> <span className="link" onClick={() => this.clickPrivacy()}>서비스 약관 및 개인 정보 보호 정책</span>에 <br />동의 함을 나타냅니다.
                        </span>
                    </div>

                </div>
                <div className="copyright">COPYRIGHT© 모슈. ALL RIGHT RESERVED</div>
            </div>

        );
    }
}

export default Login;