import React, { useEffect } from 'react';
import { connect } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';


import { ACTION_TREE } from '~/actions/ActionTree'
import Label from './Label'

import './TreeUI.scss';
import {sizing} from "@material-ui/system";
const useStyles = makeStyles({
    title: {
        // flexGrow: 1,
        // minWidth:"100px",
        // maxWidth:"100px"

        // height:40,
        // border:"1px",
        // "background-color":"#ff0000",
        // "border-color":"#000"
    },
    root: {
        '&:hover > $content': {
            backgroundColor: 'none'
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: 'none'
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'none'
        },
    },
    treenode: {
        // "border":"1px solid #666",
        "margin-top": "0px"
    }

});
// export default function FileSystemNavigator({ datas, propExpanded, dispatch, show_tool, state_tree, state_tool }) {
// export default function FileSystemNavigator(obj) {
function Temp({ dispatch, state_tree, state_tool,current_page }) {
 
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(['root']);
    const [selected, setSelected] = React.useState([]);
    let effectCount =0;
    // const [dragSelected, setDragSelected] = React.useState();
    let pause = false;
    let pauseTree = function () {
        pause = true;
        setTimeout(() => pause = false, 100)
    }
    const handleToggle = (event, nodeIds) => {
        //console.log("test handleToggle", nodeIds)
        if (pause) {
            // pause = false;
        } else {
            setExpanded(nodeIds);
            dispatch(ACTION_TREE.TREE_NODE_OPEN_CLOSE(state_tree.selected_page,nodeIds));
        }
    };

    const handleSelect = (event, nodeIds) => {
        //console.log("test handleSelect", nodeIds)

        //console.log("handleSelect nodeIds:" + nodeIds)
    };
  
    
    let labelOpen=function(nodeId){
        let nodeIds = expanded.slice();
        let idx =nodeIds.indexOf(nodeId)
        if(idx!==-1){
            nodeIds.splice(idx,1);
        }else{
            nodeIds.push(nodeId)
        }
        setExpanded(nodeIds);
        dispatch(ACTION_TREE.TREE_NODE_OPEN_CLOSE(state_tree.selected_page,nodeIds));


        //console.log("testex:"+nodeIds);
    } 
    let getTreeNodeLabel = function (data,depth) {
        return (
            <Label  labelOpen={labelOpen} data={data} pauseTree={pauseTree} depth={depth} />
        )
    }
    //console.log("state_tree.propExpanded:" + state_tree.data_root[0].expanded)


    // setTimeout(()=>function(){
    //     //console.log("......timeout")
    //     if(expanded.length!=state_tree.data_root[0].expanded.length){
    //         setExpanded(state_tree.data_root[0].expanded)
    //         //console.log("......setExpanded")
    //     }
    // },500)
    // useEffect(() => {
    //     if (expanded.length != state_tree.data_root[0].expanded.length) {
    //         setExpanded(state_tree.data_root[0].expanded)
    //         //console.log("......setExpanded")
    //     }
    // },[effectCount]);
    useEffect(() => {
   
       
    });
    effectCount++;
    // if (expanded.length != state_tree.data_root[0].expanded.length) {
        // console.log("......setExpanded")
        let getNodeExpanded=(id)=>{
            let ary =state_tree.data_root[0].children
            let length = ary.length;
            for(let z=0; z<length; z++){
                if(id === ary[z].id){
                    return ary[z].expanded;
                }
            }
            return null;
        }
        setTimeout(function(){
            // setExpanded(current_page.expanded)
            // console.log(state_tree)
            // console.log(current_page)
            if(state_tree.selected_page){
                let ex = getNodeExpanded(state_tree.selected_page.id)
                // console.log(ex);
                if(ex!=null){
                    setExpanded(ex)

                }
            }


        },1)
    // }
   
   
    let getTreeNode = function (data, parentChild, depth) {
        let children;
        if (data.children && data.children.length > 0) {
            children = getFor(data.children,depth)
        } else {
            children = null;
        }
        let label;
        label = getTreeNodeLabel(data, depth);
        let done = ""
        if (data.done) {
            done = "done"
        }
        return (
            <div key={data.id} className={"tree-item " + done}>
                <TreeItem depth={depth} nodeId={data.id} label={label} key={data.id} className={classes.treenode} onClick={() => dispatch(ACTION_TREE.TREE_SELECT(data))}>
                    {children}
                </TreeItem>
            </div>

        )
    }
   
    let getTreeRootNode = function (data) { 
        //console.log("getTreeRootNode")
        //console.log(data)
        let children;
        if (data.children.length == 0) return null;
        if(current_page==null)return null;

        if (data.children && data.children.length > 0) {
            children = getFor(current_page.children, -1)
        } else {
            children = null;
        }
        return (
            <div>
                {children}
            </div>

        )
    }
    let getFor = function (datas,depth) {
        // //console.log(datas);
        if (datas == null) return null;
        let container = [];
        let length = datas.length;
        for (let z = 0; z < length; z++) {
            container.push(
                getTreeNode(datas[z], datas,depth+1)
            )
        }
        return (<div>{container}</div>)
    }
    const keypress=(event)=>{
        if (event.which == '13') {
            event.preventDefault();
          }
    }
    return (
        <TreeView
            id={"tree-body"}
            className={classes.root}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            expanded={expanded}
            selected={selected}
            // defaultExpanded={current_page.expanded}
            // onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            onKeyPress={(e)=>keypress(e)}
        >
            {getTreeRootNode(state_tree.data_root[0])}
        </TreeView>
    );
}

const mapStateToProps = (state) => {
    //console.log("------treeui----mapStateToProps");
    let ary = state.tree.data_root[0].children
    let getCurrentPage=function(id){
        for(let z=0; z<ary.length; z++){
            if(ary[z].id ==id){
                return ary[z]
            }
        }
    }
    return {
        state_tree: state.tree,
        state_login: state.login,
        state_tool: state.tool,
        current_page:getCurrentPage(state.tree.data_root[0].selected_id)

    };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
    // treeNodeOnChange: () => dispatch(setVisibilityFilter(ownProps.filter))
})


export default connect(
    mapStateToProps
)(Temp)
