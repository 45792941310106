import React, {useState, useRef} from 'react';
import './Label.scss';
import './Label2.scss';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import {CSSTransitionGroup} from 'react-transition-group';
import {ACTION_TREE} from '~/actions/ActionTree'
import {ACTION_WEBAPP} from '~/actions/ActionWebApp'
import InputBase from '@material-ui/core/InputBase';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import CloseIcon from '@material-ui/icons/Close';
import $ from 'jquery';

const useStyles = makeStyles({
    title: {
    },
    inputTitle:{
    height:"auto",
    letterSpacing: "0.1px",
    lineHeight:"1.5rem"
    // color:"rgba(0, 0, 0, 0)"
    }
});

function Temp({state,  data, dispatch, pauseTree, depth, skinVO, labelOpen}) {
    const classes = useStyles();
    const [titleText, setTitleText] = React.useState(data.title);
    const [tfVisible, setTfVisible] = React.useState(true);
    const tfRef = useRef(null)

    const getParentChild = function (ary, id) {
        let length = ary.length;
        let rtAry = null;

        for (let z = 0; z < length; z++) {
            let item = ary[z];
            if (item.id == id) {
                return ary;
            } else {
                rtAry = getParentChild(item.children, id)
                if (rtAry != null) {
                    return rtAry
                }
            }
        }
        return null;
    }
    const textfieldHandleChange=(e,data)=>{
        if(e.target.value!= data.title){
            ACTION_TREE.TREE_EDIT_TITLE_FNC(dispatch, data, e.target.value);

        }
        // dispatch(ACTION_TREE.TREE_EDIT_TITLE(data, e.target.value))

    }
    let parentChild = getParentChild(state.tree.data_root, data.id)
    let availLeft = function (data, parentChild) {
        if (data == null) return false;
        if (depth>0) {
            return true;
        }
        return false;
    }
    let availRight = function (data, parentChild) {
        // 나보다 형이 없을 경우
        if (data == null) return false;
        let length = parentChild.length
        let bool = false;
        for (let z = 0; z < length; z++) {
            if (parentChild[z].id == data.id) {
                if (z != 0) return true;
            }
        }
        return false;
    }


    let toolShow = ""
    if (state.tool.show_tool) {
        toolShow = " tool_on"
    }
    let done = "";
    if (data.done) {
        done = " done"
    }

    let tfValue = data.title
    const tfValueChange = function (e) {

        tfValue = e.target.value;
        setTitleText(tfValue)

        ACTION_TREE.TREE_EDIT_TIMER_TITLE(dispatch, data, e.target.value);
        
    }
    let fake_tf_cls = "";
    if (titleText == "") {
        fake_tf_cls = " empty"
    }
    let clickTf = function () {
        window.$ = $;
        tfRef.current.focus({
            preventScroll: true
        });
    }
    let selected = "";
    if (state.tree.selected_tree_data && state.tree.selected_tree_data.id == data.id) {
        selected = " selected"
       
    }
    let keyPause = false
    let keyDownEvent = function (e) {
        switch (e.key) {
            case 'Enter':
                dispatch(ACTION_TREE.TREE_ADD_ENTER(data, state));
                break;
        }
        return false
    }
    let autoFocus = false;
    if (state.tree.selected_tree_data && state.tree.selected_tree_data.id === data.id) {
        autoFocus = true;
        // console.log("autofocus true," + titleText)
        // console.log(state.tree)
    } else {
        // console.log("autofocus false," + titleText)
    }
    const doneEvent = function () {
        dispatch(ACTION_TREE.TREE_DONE(data, state))
        pauseTree();
    }
    let text_color;
    let normal_text_color;
    let done_text_color;
    let done_bg_color;

    if (depth === 0) {
        done_bg_color = skinVO.first_done_bg_color;
        normal_text_color = skinVO.first_text_color;
        done_text_color = skinVO.first_done_text_color;
    } else if (depth === 1) {
        done_bg_color = skinVO.next_done_bg_color;
        ;
        normal_text_color = skinVO.next_text_color;
        done_text_color = skinVO.next_done_text_color;
    } else if (depth === 2) {
        done_bg_color = skinVO.next_done_bg_color_3;
        ;
        normal_text_color = skinVO.next_text_color_3;
        done_text_color = skinVO.next_done_text_color_3;
    } else {
        done_bg_color = skinVO.next_done_bg_color_4;
        ;
        normal_text_color = skinVO.next_text_color_4;
        done_text_color = skinVO.next_done_text_color_4;
    }
    if (data.done) {
        text_color = normal_text_color
    } else {
        text_color = done_text_color
    }
    const getMouseX = function (e) {
        if (e.touches && e.touches[0]) {
            return e.touches[0].clientX
        }
    }
    const getMouseY = function (e) {
        if (e.touches && e.touches[0]) {
            return e.touches[0].clientY
        }
    }
    let tsx;
    let tsy;

    let tmx;
    let tmy;
    let frame;
    console.log("label.title, :"+data.title,"titleText:",titleText)
    if(data.title!=titleText){
        setTitleText(data.title)
    }
    const touchStart = (e) => {
        // console.log("touch start e:", e)
        tsx = getMouseX(e);
        tsy = getMouseY(e);

        frame = 0;

        const touchMove = (e) => {
            frame += 1;
            tmx = getMouseX(e);
            tmy = getMouseY(e);

        }
        const touchEnd = (e) => {
            let tdx = (tmx - tsx) / frame;
            let tdy = (tmy- tsy) / frame;

            if(Math.abs(tdx)-Math.abs(tdy)<0){
                eventDelete();
                return 
            }
            console.log("tdx:"+tdx,",tdy:",tdy)
            let m = 3
            if (tdx < -m) {
                dragSuc(1, tdx)
            }
            if (tdx > m) {
                dragSuc(-1, tdx)
            }
            eventDelete();
        }
        const dragSuc = (dir, tdx) => {
            // console.log("dir:" + dir + ", tdx:" + tdx)
            if (dir > 0) {
                // 오른쪽

                const left = availLeft(data,parentChild)
                // console.log("dir.. left:"+left)

                if(left){
                    dispatch(ACTION_TREE.TREE_EDIT_NODE_LEFT(data))
                }
            } else {
                //왼쪽
                const right = availRight(data,parentChild)
                // console.log("dir.. right:"+right)
                if(right){
                    dispatch(ACTION_TREE.TREE_EDIT_NODE_RIGHT(data))
                }
            }
        }
        const eventDelete = () => {
            window.removeEventListener('touchmove', touchMove);
            window.removeEventListener('touchcancel', touchEnd);
            window.removeEventListener('touchend', touchEnd);
        }
        const eventAdd = () => {
            window.addEventListener('touchmove', touchMove);
            window.addEventListener('touchcancel', touchEnd);
            window.addEventListener('touchend', touchEnd);
        }
        eventAdd();
    }
    const boxColor = "0.2em 0 0 "+done_bg_color+", -0.2em 0 0 "+done_bg_color
    let fakeTfStyle;
    if(data.done){
        fakeTfStyle={backgroundColor: done_bg_color, boxShadow:boxColor, color:text_color};
    }else{
        fakeTfStyle={  color:text_color};
    }

    function isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    const getInutWrap=()=>{
        return (
            <div className="input-child-wrap">
                <span className={"tx-bg" + done }>
                    <span className={"fake-tf off" + fake_tf_cls}  style={fakeTfStyle}>{titleText}</span>
            
                    <span className={"selection-ab-wrap"}>
                        <span className="selection">{titleText}</span>
                    </span>
                </span>
                {
                    <InputBase
                    placeholder="I can do it"
                    className={classes.inputTitle+" input-label-title"}
                    defaultValue={titleText}
                    inputRef={tfRef}
                    autoFocus={autoFocus}
                    inputProps={{'aria-label': 'naked'}}
                    fullWidth
                    onChange={(e) => tfValueChange(e, data)}
                    onBlur={(e) => textfieldHandleChange(e, data)}
                    size="small"
                    onKeyUp={(e) => keyDownEvent(e)}
                    style={{color: text_color}}
                    multiline
                />
                }
            
            </div>
        )
    }
    const clickAlram=()=>{
        dispatch(ACTION_WEBAPP.LOCAL_NOTIFICATION_TIME_SHOW(data,true))
        setTimeout(()=>{
            dispatch(ACTION_WEBAPP.LOCAL_NOTIFICATION_TIME_SHOW_ANI(data,true))
        },1)

    }

    return (
        <div className="label-whole-wrap label2">
            <div className={"label-cont " + toolShow} onTouchStart={touchStart}>
                <div className={"label-child-cont "+selected}>
                    {
                    isMobile()&&
                        // <span className={"input-wrap"} onTouchStart={() => clickTf()}>
                        <span className={"input-wrap"} onClick={() => clickTf()}>
                            {getInutWrap()}
                        </span>
                    }
                    {
                    !isMobile()&&
                        <span className={"input-wrap"} onClick={() => clickTf()}>
                            {getInutWrap()}
                        </span>
                    }
                    <span className="tool-right">
                        <IconButton className="tool_alarm" size="small" aria-label="delete" onClick="" onClick={()=>clickAlram()}>
                        <AccessAlarmIcon fontSize="small"/>
                        </IconButton>
                        <IconButton size="small" aria-label="delete" onClick="" onClick={()=>dispatch(ACTION_TREE.TREE_DELETE(data))}>
                        <CloseIcon fontSize="small"/>
                        </IconButton>
                    </span>

                    <CSSTransitionGroup
                        transitionName="iconfade"
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={1000}>
                        {state.tool.show_tool &&
                        <span className="tool-wrap done-wrap">
                            {!data.done &&
                            <span className="iwrap">
                                <IconButton size="small" aria-label="delete" onClick={() => doneEvent()}>
                                <RadioButtonUncheckedIcon fontSize="small"/>
                                </IconButton>
                            </span>
                            }
                            {data.done &&
                            <span className="iwrap">
                                <IconButton size="small" aria-label="delete" onClick={() => doneEvent()}>
                                <DoneIcon fontSize="small"/>
                                </IconButton>
                            </span>
                            }
                        </span>
                        }
                    </CSSTransitionGroup>
                </div>
            </div>
            <div className="arrow-cont pa" onClick={() => labelOpen(data.id)}></div>
        </div>
    )
}
const mapStateToProps = (state) => {
    let skinVO;
    if (state.config.config.skin == 0) {
        skinVO = state.config.config.skin0VO;
    }



    return {
        state: state,
        skinVO: skinVO,

    };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
})


export default connect(
    mapStateToProps
)(Temp)

