import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import PersonIcon from '@material-ui/icons/Person';

import { connect } from 'react-redux'
;
import SettingHeader from '../SettingHeader';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,

  },
  list_radio: {
    // paddingTop:"13px",
    // paddingBottom:"13px"
    // height:"32px"
    paddingTop: "5px",
    paddingBottom: "5px"

  }
}));


function Temp({ dispatch,state_login, state_config, destroy }) {
  const classes = useStyles();

  const appversion=()=>{
    // return true;
    if(window.webkit||window.Android){
        let agent = window.navigator.userAgent;
        let c = "moshview";
        if(agent.indexOf(c)!=-1){
            // return true;
            let ary = agent.split(" ");
            for(var z=0; z<ary.length; z++){
                let d = ary[z].split("/")
                if(d[0]==c){
                    return d[1]
                }
            }
            return 0
        }else{
            return 0;
        }
       
    }
    return 0;
}

  let deviceStr=appversion();

  return (
    <div className={classes.root}>
      <SettingHeader title="버전 정보" back={true}/>
      <List component="nav" aria-label="secondary mailbox folders">
        <ListItem button  >
          <ListItemIcon>
            <LanguageIcon /> 
          </ListItemIcon>
          <ListItemText primary="w1.07" />
        </ListItem>
        <ListItem button  >
          <ListItemIcon> 
            <PermDeviceInformationIcon /> 
          </ListItemIcon>
          <ListItemText primary={"d"+deviceStr} />
        </ListItem>
        </List>
    </div>
  );
}
const mapStateToProps = (state) => {
  //console.log(state)
  return {
    state_tree: state.tree,
    state_login: state.login,
    state_tool: state.tool,
    state_config: state.config,
  };
}

export default connect(
  mapStateToProps,
)(Temp)


