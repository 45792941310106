import React from 'react';
import Contents from './Contents';
function App() {
    const isApp=()=>{
        // return true;
        if(window.webkit||window.Android){
            if(window.Android){
                let agent = window.navigator.userAgent;
                let c = "moshview";
                if(agent.indexOf(c)!=-1){
                    return true;
                }else{
                    return false;
                }
            }else{
                // 여기가 ios app
                if(window.location.href.indexOf("todo.mosh.kr")!=-1){
                    window.location.href="https://todo-mosh-kr-1fe63.firebaseapp.com/"
                }
                return true;
            }
        }
        return false;
    }
    let is_app=""
    if(isApp()){
        is_app=" is_app"
    }
  return (
    <div className={"App mobile"+is_app}>
    {/* <div className="App "> */}
       <Contents/>
    </div>
  );
}

export default App;
